// @import url("https://fast.fonts.net/lt/1.css?apiType=css&c=a89f2537-f215-40bf-bcc7-d734757374fb&fontids=1488866,1488878,1488902,1488914,1488938,5383870");

/* Thin */
@font-face {
    font-family: 'Helvetica Neue LT W01_31488866';
    src: url('/fonts/1488866/4ff9f3fa-9221-4fc5-97e6-93572b6efa24.woff2')
            format('woff2'),
        url('/fonts/1488866/ca8d25cd-e264-4404-b271-4afc45c779c4.woff')
            format('woff');
}

/* Light */
@font-face {
    font-family: 'Helvetica Neue LT W01_41488878';
    src: url('/fonts/1488878/3a46542f-f429-4821-9a88-62e3be54a640.woff2')
            format('woff2'),
        url('/fonts/1488878/50ac1699-f3d2-47b6-878f-67a368a17c41.woff')
            format('woff');
}

/* Medum */
@font-face {
    font-family: 'Helvetica Neue LT W01_65 Md';
    src: url('/fonts/1488902/5b1fbd62-45dc-4433-a7df-a2b24a146411.woff2')
            format('woff2'),
        url('/fonts/1488902/050b1948-f226-4d20-a65a-15d8ed031222.woff')
            format('woff');
}

/* Bold */
@font-face {
    font-family: 'Helvetica Neue LT W01_71488914';
    src: url('/fonts/1488914/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2')
            format('woff2'),
        url('/fonts/1488914/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff')
            format('woff');
}

/* Black */
@font-face {
    font-family: 'Helvetica Neue LT W01_91488938';
    src: url('/fonts/1488938/bd8486cc-4614-412d-ba1d-15e1a7c9fada.woff2')
            format('woff2'),
        url('/fonts/1488938/a7bdf5a9-752e-4e69-b17c-b7e62575b07a.woff')
            format('woff');
}

/* Regular */
@font-face {
    font-family: 'Helvetica Neue for IB W01 Rg';
    src: url('/fonts/5383870/46c659aa-5e7e-48f1-a8bf-13e3c36baa3b.woff2')
            format('woff2'),
        url('/fonts/5383870/8e5737a2-66d4-4f56-9300-36df9b2668b6.woff')
            format('woff');
}
