@import 'styles/includes';

.Button {
    $root: &;

    position: relative;
    border-radius: 4px;
    display: inline-block;
    font-family: $fontMedium;
    line-height: 1.32;
    border: none;
    white-space: nowrap;
    overflow: hidden;
    text-decoration: none;
    padding: 14px 24px;
    font-size: 1.6rem;

    path {
        fill: $colorWhite;
    }

    @include media(m) {
        font-size: 1.8rem;
        padding: 16px 32px;
    }

    &--FontSmall {
        font-family: $fontLight;
        font-size: 1.4rem;
        cursor: pointer;

        #{$root}__Inner > #{$root}__Text {
            margin-right: 0.8rem;
            margin-top: 2px;
        }

        svg {
            position: relative;
        }
    }

    &--Small {
        padding: 14px 28px;
        font-size: 1.6rem;
    }

    &--Primary {
        background-color: $colorRed50;
        color: $colorWhite;
    }

    &--Secondary {
        border: 1px solid $colorBlack;
        background-color: $colorWhite;
        color: $colorBlack;
        transition: border 0.5s ease;
        will-change: border;

        path {
            fill: $colorBlack;
            will-change: fill;
            transition: fill 0.25s ease;
        }

        @media (hover: hover) {
            &:hover:not([disabled]) {
                &:hover {
                    border: 1px solid $colorWhite;

                    #{$root}__Text {
                        color: $colorWhite;
                    }

                    path {
                        fill: $colorWhite;
                    }
                }
            }
        }
    }

    &--OnlyIcon {
        background-color: transparent;
        padding: 0;

        path {
            fill: $colorBlack;
        }
    }

    &--PlayerListFooter {
        color: $colorBlack;
        text-underline-offset: 2px;
        font-family: $fontLight;
        font-size: 1.4rem;
        text-decoration: underline;
        display: flex;
        align-items: center;
        font-weight: normal;
        background-color: transparent;
        padding: 8px 16px;

        path {
            fill: $colorBlack;
        }

        &:hover {
            color: $colorRed50;

            svg * {
                fill: $colorRed50;
            }
        }

        @include media(m) {
            margin-top: 0;
            font-size: 1.6rem;
            padding: 16px;
        }
    }

    &--AccordionFooter {
        text-underline-offset: 2px;
        font-family: $fontLight;
        font-size: 1.4rem;
        text-decoration: underline;
        margin-top: 12px;
        display: flex;
        align-items: center;
        font-weight: normal;
        background-color: transparent;
        padding: 0;

        path {
            fill: $colorBlack;
        }

        &:hover {
            color: $colorRed50;

            svg * {
                fill: $colorRed50;
            }
        }

        @include media(m) {
            margin-top: 0;
            position: absolute;
            left: 16px;
            bottom: 13px;
            font-size: 1.6rem;
        }
    }

    &--Tertiary {
        padding: 0;
        background-color: $colorWhite;
        color: $colorBlack;
        text-decoration: underline;
        text-decoration-color: transparent;
        transition: text-decoration-color 0.25s ease;
        text-underline-offset: 4px;

        path {
            fill: $colorBlack;
            will-change: fill;
            transition: fill 0.25s ease;
        }

        #{$root}__Text {
            transition: color 0.25s ease;
        }

        @media (hover: hover) {
            &:hover:not([disabled]) {
                text-decoration-color: $colorRed50;

                #{$root}__Text {
                    color: $colorRed50;
                }

                path {
                    fill: $colorRed50;
                }
            }
        }
    }

    &__Text {
        z-index: 1;
        position: relative;
        will-change: color;
        transition: color 0.25s ease;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__Inner {
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            margin: 0 0 0 8px;
        }
    }

    &__Bar {
        width: 25%;
        height: 0%;
        position: absolute;
        bottom: 0;
        background-color: $colorRed90;
        will-change: height;

        #{$root}--Secondary & {
            background-color: $colorBlack;
        }

        &--1 {
            transition: height 0.5s ease;
            left: 0;
        }

        &--2 {
            transition: height 0.4s ease;
            left: 25%;
        }

        &--3 {
            transition: height 0.3s ease;
            left: 50%;
        }

        &--4 {
            transition: height 0.2s ease;
            left: 75%;
        }
    }

    @media (hover: hover) {
        &--Primary:not([disabled]):hover &__Bar,
        &--Secondary:not([disabled]):hover &__Bar {
            &--1 {
                height: 100%;
            }

            &--2 {
                height: 100%;
            }

            &--3 {
                height: 100%;
            }

            &--4 {
                height: 100%;
            }
        }
    }

    &[disabled] {
        pointer-events: none;
    }
}
