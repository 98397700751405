%h1 {
    font-family: $fontLight;
    font-size: 4.0rem;
    line-height: 1.32;
    color: inherit;

    @include media(m) {
        font-size: 5.2rem;
    }
}

%h2 {
    font-family: $fontLight;
    font-size: 3.4rem;
    line-height: 1.32;
    color: inherit;

    @include media(m) {
        font-size: 4rem;
    }
}

%h3 {
    font-family: $fontLight;
    font-size: 2.8rem;
    line-height: 1.32;
    color: inherit;

    @include media(m) {
        font-size: 3.2rem;
    }
}

%h4 {
    font-family: $fontLight;
    font-size: 2.4rem;
    line-height: 1.32;
    color: inherit;

    @include media(m) {
        font-size: 2.6rem;
    }
}

%h4-focus {
    font-family: $fontLight;
    font-size: 2.2rem;
    line-height: 1.32;
    color: inherit;

    @include media(m) {
        font-size: 2.6rem;
        line-height: 1.5;
    }
}

%h5 {
    font-family: $fontLight;
    font-size: 2rem;
    line-height: 1.32;
    color: inherit;

    @include media(m) {
        font-size: 2.2rem;
    }
}

%preamble {
    font-size: 1.8rem;
    line-height: 1.33;
    font-family: $fontLight;

    @include media(m) {
        font-size: 2rem;
    }
}

%article-preamble {
    font-size: 2.0rem;
    line-height: 1.4;
    color: $colorGray90;
    font-family: $fontLight;

    @include media(m) {
        font-size: 2.2rem;
    }
}

%p {
    font-family: $fontLight;
    font-size: 1.6rem;
    line-height: 1.47;
}

%date {
    font-size: 1.6rem;
    color: $colorBlack;
}

%container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 $spacingM;
    width: 100%;

    @include media(m) {
        max-width: calc(126rem + ($spacingM * 2));
    }
}

%article-container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 $spacingM;
    width: 100%;

    @include media(m) {
        max-width: calc(104.6rem + ($spacingM * 2));
    }
}

%rich-text {
    color: $colorGray90;

    h2,
    h3,
    h4,
    h5,
    h6 {
        word-break: break-word;
        font-family: $fontLight;
        margin-bottom: 12px;
    }

    h1 {
        @extend %h1;
        font-family: $fontLight;
    }

    h2 {
        @extend %h2;
    }

    h3 {
        @extend %h3;
    }

    h4 {
        @extend %h4;
    }

    h5 {
        @extend %h5;
    }

    p {
        @extend %p;
        margin-bottom: calc($spacingM + $spacingS);
    }

    a {
        border-bottom: 1px;
        border-style: solid;
        border-color: inherit;
    }

    b {
        font-family: $fontBold;
    }

    em {
        font-style: italic;
    }

    i {
        font-style: italic;
    }

    ul,
    ol {
        margin-bottom: 24px;
        margin-left: 12px;

        @include media(m) {
            margin-bottom: 36px;
        }
    }

    ol {
        list-style-type: decimal;
        padding-left: 17px;

        @include media(m) {
            padding-left: 19px;
        }
    }

    li {
        line-height: 1.4;
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }

        @include media(m) {
            font-size: 1.6rem;
        }
    }

    ul>li {
        padding-left: 25px;
        position: relative;

        &::before {
            background-color: $colorRed50;
            border-radius: 50%;
            content: '';
            display: inline-block;
            height: 7px;
            left: 0;
            position: absolute;
            top: 7px;
            width: 7px;

            @include media(m) {
                top: 10px;
            }
        }
    }

    ol>li {
        padding-left: 8px;

        @include media(m) {
            padding-left: 6px;
        }

        &::marker {
            color: $colorRed50;
            flex-shrink: 0;
            font-weight: 700;
            margin-right: 15px;
        }
    }

    a {
        &:hover {
            color: $colorRed50;
        }
    }

    figcaption {
        font-family: $fontRegular;
        font-size: 1.2rem;
        line-height: (20 / 12);
        margin-bottom: 20px;
        margin-top: 16px;

        @include media(m) {
            margin-bottom: 40px;
        }
    }
}