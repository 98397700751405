@import '../../styles/includes';

.ProductImageCard {
    $root: &;

    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    color: $colorWhite;
    text-decoration: none;
    background-color: $colorWhite;
    box-shadow: 0 4px 24px -1px rgba(0, 0, 0, 0.2);
    will-change: box-shadow;
    transition: box-shadow 0.25s ease;

    @media (hover: hover) {
        &:hover {
            box-shadow: 0 8px 24px -1px rgba(0, 0, 0, 0.5);
        }
    }

    &__Images {
        display: block;
        padding: $spacingM;
        max-width: 180px;
        margin: 0 auto;

        @include media(m) {
            margin: 0;
            max-width: none;
            padding: $spacingL;
        }
    }

    &__ProductLogoImageWrapper {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 18px;
        margin-bottom: 4px;
        will-change: filter, opacity;
        opacity: 1;
        transition: filter 0.25s 0.15s ease;

        @include media(m) {
            height: 24px;
            margin-bottom: 8px;

            @media (hover: hover) {
                #{$root}:hover & {
                    animation: imageInvertation 0.25s ease forwards;
                }
            }
        }
    }

    &__ProductLogoImage {
        object-position: left center;
        opacity: 0;
        will-change: opacity, filter;
        transition: opacity 0.5s ease;
        transform: scale(1);
        z-index: -1;

        &--Loaded {
            opacity: 1;
        }
    }

    &__ProductImageWrapper {
        position: relative;
        z-index: 1;
        width: 100%;
    }

    &__ProductImage {
        position: static !important;
        max-height: 96px;
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.5s ease;
        transform: scale(1);
        z-index: -1;
        object-position: left;

        &--Loaded {
            opacity: 1;
        }
    }

    &__Content {
        display: none;

        @include media(m) {
            display: block;
            background-color: $colorRed50;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            z-index: 1;
            padding: $spacingL;
            will-change: opacity, transform;
            opacity: 0;
            transition: opacity 0.15s ease, transform 0.25s ease;
            transform: scale(0.95);

            @media (hover: hover) {
                #{$root}:hover & {
                    opacity: 1;
                    transform: scale(1);
                }
            }
        }
    }

    &__Description {
        @extend %p;
        margin-top: 3rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        -webkit-box-orient: vertical;
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.25s 0.15s ease;

        @media (hover: hover) {
            #{$root}:hover & {
                opacity: 1;
            }
        }
    }
}

@keyframes imageInvertation {
    0% {
        opacity: 1;
        filter: brightness(1) invert(0);
    }

    75% {
        opacity: 0;
        filter: brightness(1) invert(0);
    }

    100% {
        opacity: 1;
        filter: brightness(0) invert(1);
    }
}