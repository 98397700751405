$breakpoints: (
    xs: 375px,
    s: 532px,
    m: 768px,
    ml: 1024px,
    ls: 1200px,
    l: 1440px,
    xl: 1680px,
    xxl: 1930px,
);

$maxwidths: (
    xs: 345px,
    s: 500px,
    m: 720px,
    ml: 920px,
    l: 1260px,
    xl: 1440px,
);

$spacingXs: 4px;
$spacingS: 8px;
$spacingM: 16px;
$spacingL: 32px;

$colorRed90: #54100a;
$colorRed70: #70150e;
$colorRed50: #8c1a11;

$error: #FF0000;

$colorGreen: #56c86f;

// $colorSystemGreen: #1dac2c;
// $colorSystemRed: #d32f2f;
// $colorSystemRed10: #fbebeb;

$colorWhite: #ffffff;

$colorGray90: #212121;
$colorGray80: #424242;
$colorGray70: #616161;
$colorGray60: #757575;
$colorGray50: #9e9e9e;
$colorGray40: #bdbdbd;
$colorGray35: #d3d3d3;
$colorGray30: #e0e0e0;
$colorGray20: #eeeeee;
$colorGray10: #f5f5f5;
$colorGray5: #fafafa;

$colorBlack: #000000;

$fontBlack: 'Helvetica Neue LT W01_91488938', sans-serif;
$fontBold: 'Helvetica Neue LT W01_71488914', sans-serif;
$fontMedium: 'Helvetica Neue LT W01_65 Md', sans-serif;
$fontRegular: 'Helvetica Neue for IB W01 Rg', sans-serif;
$fontLight: 'Helvetica Neue LT W01_41488878', sans-serif;