@import '../../styles/includes';

.ProductImageCardList {
    $root: &;

    &--NoMargin {
        @include media(m) {
            margin: -50px 0;
        }
    }
    &__Title {
        @extend %h2;

        #{$root}--NoMargin & {
            font-size: 22px;
            @include media(m) {
                font-size: 26px;
            }
        }
    }
    &__Container {
        @extend %container;
    }

    &__Row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: calc($spacingM + $spacingS);
    }

    &__List {
        display: flex;
        flex-direction: column;

        @include media(m) {
            flex-wrap: wrap;
            flex-direction: row;
            margin-left: -24px;
            width: calc(100% + 24px);
        }
    }

    &__Item {
        width: 100%;
        margin: 0 0 16px 0;
        display: flex;

        @include media(m) {
            width: calc((100% / 2) - 24px);
            margin: 0 0 24px 24px;
        }

        @include media(ml) {
            width: calc((100% / 3) - 24px);
            margin: 0 0 24px 24px;
        }
    }

    &__Link {
        &--Desktop {
            display: none;

            @include media(m) {
                display: block;
            }
        }

        &--Mobile {
            display: block;

            @include media(m) {
                display: none;
            }
        }
    }
}
